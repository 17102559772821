/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { UseFormReset } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { useFields } from '@libs/common/v2';
import { ValueOf } from '@libs/common/v2/utils';

import { DomainDictionaryEntry, DomainDictionaryEnum } from '@libs/domain/config';

import { useProxyDetailsQuery } from '../api';
import { ProxyFields } from '../model/proxy.model';

interface IProps {
  proxyId?: string;
  hiddenFieldIds?: (keyof ProxyFields)[];
  folderId?: string;
  applicationType?: ValueOf<typeof DomainDictionaryEntry.APPLICATION_CATEGORY>;
  reset: UseFormReset<ProxyFields>;
  isPostCityRequired?: boolean;
  isCitizenshipFieldRequired?: boolean;
}

function useProxyFields({
  proxyId,
  reset,
  folderId,
  applicationType,
  isPostCityRequired,
  isCitizenshipFieldRequired,
  hiddenFieldIds
}: IProps) {
  const { data, isLoading } = useProxyDetailsQuery(proxyId, { enabled: Boolean(proxyId) });

  const isWLDepartment: boolean = [
    DomainDictionaryEntry.APPLICATION_CATEGORY.BROKERAGE_IN_TRANSPORT_OF_PASSENGERS,
    DomainDictionaryEntry.APPLICATION_CATEGORY.ROAD_CARRIER_GOODS_CARRIAGE_ABRIDGEMENT
  ].includes(applicationType);
  const isDriverCertivicateDepartment =
    DomainDictionaryEntry.APPLICATION_CATEGORY.DRIVER_CERTIFICATE === applicationType;

  const isTypeKeyDisabled: boolean = !!folderId || isWLDepartment || isDriverCertivicateDepartment;

  const getInitialTypeKey = () => {
    if (folderId) {
      return { value: DomainDictionaryEntry.PROXY_TYPE.PERMANENT };
    }
    return isWLDepartment || isDriverCertivicateDepartment
      ? { value: DomainDictionaryEntry.PROXY_TYPE.ONE_TIME }
      : null;
  };

  const getInitialCitizenship = () => {
    return isCitizenshipFieldRequired ? { value: DomainDictionaryEntry.COUNTRY_CODE.POLAND } : null;
  };

  const initialValues: ProxyFields = {
    typeKey: getInitialTypeKey(),
    name: null,
    surname: null,
    paid: false,
    citizenship: getInitialCitizenship(),
    notes: null,
    postCode: null,
    postCity: null,
    city: null,
    street: null,
    propertyNumber: null,
    apartmentNumber: null,
    validUntil: null,
    created: null,
    author: null,
    modified: null,
    modifier: null,
    version: null,
    authorizations: null
  };

  useEffect(() => {
    if (reset) {
      if (data) {
        const authorizations = data?.authorizations;

        reset({
          typeKey: data?.typeKey ? { value: data?.typeKey } : null,
          citizenship: data?.citizenship ? { value: data?.citizenship } : null,
          name: data?.name ?? null,
          surname: data?.surname ?? null,
          paid: data?.paid ?? null,
          postCode: data?.postCode ?? null,
          postCity: data?.postCity ?? null,
          city: data?.city ?? null,
          street: data?.street ?? null,
          propertyNumber: data?.propertyNumber ?? null,
          apartmentNumber: data?.apartmentNumber ?? null,
          validUntil: data?.validUntil ?? '',
          notes: data?.remarks ?? null,
          author: data?.author?.name ?? null,
          created: data?.created ?? null,
          modifier: data?.modifier?.name ?? null,
          modified: data?.modified ?? null,
          version: data?.version ?? null,
          authorizations: isEmpty(authorizations) ? null : authorizations.map(item => ({ value: item }))
        });
      } else {
        reset(initialValues);
      }
    }
  }, [data, reset]);

  const { renderField } = useFields<ProxyFields>(
    [
      {
        id: 'name',
        type: 'TEXT',
        validation: {
          required: true
        }
      },
      {
        id: 'surname',
        type: 'TEXT',
        validation: {
          required: true,
          maxLength: 80
        }
      },
      {
        id: 'typeKey',
        type: 'DICTIONARY',
        dictionaryName: DomainDictionaryEnum.PROXY_TYPE,
        disabled: isTypeKeyDisabled,
        validation: {
          required: true
        }
      },
      {
        id: 'paid',
        type: 'BOOLEAN',
        validation: {
          required: true
        }
      },
      {
        id: 'citizenship',
        type: 'DICTIONARY',
        dictionaryName: DomainDictionaryEnum.COUNTRY_CODE,
        validation: {
          required: true
        }
      },
      {
        id: 'notes',
        type: 'TEXT',
        lines: 2
      },
      {
        id: 'authorizations',
        type: 'DICTIONARY',
        dictionaryName: DomainDictionaryEnum.SCOPES_OF_FOREIGN_PERMITS_AUTHORIZATIONS,
        isMultiple: true,
        validation: {
          required: true
        }
      },
      {
        id: 'validUntil',
        type: 'DATETIME'
      },
      {
        id: 'postCode',
        type: 'TEXT',
        validation: {
          required: true
        },
        inputMask: { mask: '99-999', alwaysShowMask: true }
      },
      {
        id: 'postCity',
        type: 'TEXT',
        validation: {
          required: isPostCityRequired,
          maxLength: 100
        }
      },
      {
        id: 'city',
        type: 'TEXT',
        validation: {
          required: true,
          maxLength: 100
        }
      },
      {
        id: 'street',
        type: 'TEXT',
        validation: {
          required: true,
          maxLength: 100
        }
      },
      {
        id: 'propertyNumber',
        type: 'TEXT',
        validation: {
          maxLength: 10,
          required: true
        }
      },
      {
        id: 'apartmentNumber',
        type: 'TEXT',
        validation: {
          maxLength: 5
        }
      },
      {
        id: 'created',
        type: 'DATETIME'
      },
      {
        id: 'author',
        type: 'TEXT'
      },
      {
        id: 'modified',
        type: 'DATETIME'
      },
      {
        id: 'modifier',
        type: 'TEXT'
      }
    ],
    { translationPath: 'proxy:field', hiddenFieldIds }
  );

  return { renderField, isDataLoading: isLoading };
}

export default useProxyFields;
